<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="医生姓名：">
				<el-input v-model="filters.keyword" placeholder="医生姓名：" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>
    <el-table :data="dataList" border style="width: 100%;">
      <el-table-column prop="name" header-align="center" align="center" label="医生姓名"></el-table-column>
      <el-table-column prop="payeeName" header-align="center" align="center" label="收款人姓名"></el-table-column>
      <el-table-column prop="totalFee" header-align="center" align="center" label="提现金额（元）">
        <template slot-scope="scope">
          {{scope.row.cashOutFee}}
        </template>
      </el-table-column>
      <el-table-column prop="actualFee" header-align="center" align="center" label="实际到账（元）">
        <template slot-scope="scope">
          {{scope.row.actualFee}}
        </template>
      </el-table-column>
      <el-table-column prop="tax" header-align="center" align="center" label="代扣税（元）">
        <template slot-scope="scope">
          {{scope.row.tax}}
        </template>
      </el-table-column>
      <el-table-column prop="accumulatedMonthCashOutFee" header-align="center" align="center" label="当月累计提现（元）">
        <template slot-scope="scope">
          {{scope.row.accumulatedMonthCashOutFee}}
        </template>
      </el-table-column>
      <el-table-column prop="accumulatedCashOutFee" header-align="center" align="center" label="累计提现（元）">
        <template slot-scope="scope">
          {{scope.row.accumulatedCashOutFee}}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="payeeName" header-align="center" align="center" label="收款人信息">
        <template slot-scope="scope">
          {{scope.row.payeeName}}
        </template>
      </el-table-column>
      <el-table-column prop="payeeName" header-align="center" align="center" label="收款人信息">
        <template slot-scope="scope">
          {{scope.row.payeeName}}
        </template>
      </el-table-column> -->
      <el-table-column prop="status" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          {{
            getTextByStatus(scope.row.transfersStatus, scope.row.status)
          }}
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" header-align="center" align="left" label="申请时间"></el-table-column>
      <el-table-column header-align="center" align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="detailHandle(scope.row)">查看详情</el-button>
          <el-button v-if="scope.row.status === 0" type="text" size="small" @click="auditHandle(scope.row, 1)">通过</el-button>
          <el-button v-if="scope.row.status === 0" type="text" size="small" @click="auditHandle(scope.row, 2)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!-- 详情 对话框 -->
		<el-dialog title="申请详情" :visible.sync="dialogFormVisible">
			<el-form :model="form" :rules="auditRules" ref="stockout">
				<el-form-item label="医生姓名：" :label-width="formLabelWidth">
          <div>
            <b>{{form.name}}</b>
          </div>
				</el-form-item>
        <el-form-item label="提现方式：" :label-width="formLabelWidth">
          <div>
            <b>{{form.transfersMethod === 1 ? '微信零钱' : '银行卡'}}</b>
          </div>
				</el-form-item>
        <el-form-item label="收款人信息：" :label-width="formLabelWidth">
					<div>
            <b>姓名：{{form.payeeName}}</b>
          </div>
          <div v-if="form.payeeBankName">
            <b>银行名称：{{form.payeeBankName}}</b>
          </div>
          <div v-if="form.payeeBankCode">
            <b>银行卡号：{{form.payeeBankCode}}</b>
          </div>
				</el-form-item>
        <el-form-item label="提现单号：" :label-width="formLabelWidth">
          <div>
            <b>{{form.partnerTradeNO}}</b>
          </div>
				</el-form-item>
        <el-form-item label="提现金额：" :label-width="formLabelWidth">
          <div>
            <b>{{form.cashOutFee}}（元）</b>
          </div>
				</el-form-item>
        <el-form-item label="实际到账：" :label-width="formLabelWidth">
          <div>
            <b>{{form.actualFee}}（元）</b>
          </div>
				</el-form-item>
        <el-form-item label="代扣税：" :label-width="formLabelWidth">
          <div>
            <b>{{form.tax}}（元）</b>
          </div>
				</el-form-item>
        <el-form-item label="当月累计提现：" :label-width="formLabelWidth">
					<div>
            <b>{{form.accumulatedMonthCashOutFee}}（元）</b>
          </div>
				</el-form-item>
        <el-form-item label="累计提现：" :label-width="formLabelWidth">
					<div>
            <b>{{form.accumulatedCashOutFee}}（元）</b>
          </div>
				</el-form-item>
        <el-form-item label="计税规则：" :label-width="formLabelWidth">
					<div>
            <b>{{form.taxFormula}}={{form.tax}}</b>
          </div>
				</el-form-item>
        <el-form-item label="状态：" :label-width="formLabelWidth">
					<div>
            <b>{{getTextByStatus(form.transfersStatus, form.status)}}</b>
          </div>
				</el-form-item>
        <el-form-item v-if="form.reason" label="原因：" :label-width="formLabelWidth">
					<div>
            <b>{{form.reason}}</b>
          </div>
				</el-form-item>
        <el-form-item v-if="form.paymentNO" label="微信流水号：" :label-width="formLabelWidth">
					<div>
            <b>{{form.paymentNO}}</b>
          </div>
				</el-form-item>
			</el-form>
			<!-- <div slot="footer" class="dialog-footer">
				<el-button @click="clickToOut()">取 消</el-button>
				<el-button type="primary" @click="clickToOut(1)">确 定</el-button>
			</div> -->
		</el-dialog>
		<!-- 审核 对话框 -->
		<el-dialog title="提示" :visible.sync="auditDialogVisible">
			<el-form :model="auditForm" :rules="auditRules" ref="auditF">
				<el-form-item label="提现单号" :label-width="formLabelWidth" prop="partnerTradeNO">
					<el-input v-model="auditForm.partnerTradeNO" readOnly>
					</el-input>
				</el-form-item>
				<el-form-item label="收款人姓名" :label-width="formLabelWidth" prop="partnerTradeNO">
					<el-input v-model="auditForm.payeeName" readonly>
					</el-input>
				</el-form-item>
				<el-form-item :label="`审核${auditForm.status === 1 ? '通过' : '拒绝'}原因`" :label-width="formLabelWidth" prop="reason">
					<el-input type="textarea" v-model="auditForm.reason" autocomplete="on"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="auditDialogVisible = false;">取 消</el-button>
				<el-button type="primary" @click="audit">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { dateFormat } from '@/utils';
export default {
	data () {
		return {
			form: {},
			formLabelWidth: '120px',
			filters: {
				keyword: ''
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dialogFormVisible: false,
			auditDialogVisible: false,
			auditForm: {
				id: '',
				partnerTradeNO: '',
				payeeName: '',
				reason: '',
				status: 1 // 1通过，2拒绝
			},
			cancelVisible: false,
			auditRules: {
				reason: [
					{ required: true, message: '请输入原因', trigger: 'blur' }
				],
				partnerTradeNO: [
					{ required: true, message: '请输入提现单号', trigger: 'blur' }
				]
			}
		};
	},
	created () {
		this.search();
	},
	methods: {
		getTextByStatus (status1, status2) {
			const ret1 = ['审核中', '审核中', '提现成功', '提现失败'];
			const ret2 = ['待处理', '审核同意', '审核拒绝'];
			if (status2 === 1) return ret1[status1];
			return ret2[status2];
		},
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('cashoutList', {
				...this.filters,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						r.createdAt = dateFormat(new Date(r.createdAt), 'yyyy-MM-dd hh:mm:ss');
						return r;
					});
				}
			});
		},
		detailHandle (item) {
			this.form = { ...item };
			this.dialogFormVisible = true;
		},
		auditHandle (item, status) {
			this.auditForm.partnerTradeNO = item.partnerTradeNO;
			this.auditForm.payeeName = item.payeeName;
			this.auditForm.status = status;
			this.auditDialogVisible = true;
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		audit () {
			this.$refs.auditF.validate((valid) => {
				if (valid) {
					this.$root.request('cashoutAudit', this.auditForm).then((data) => {
						if (data && data.ok === 1) {
							this.$root.elMsg('操作成功');
							this.auditDialogVisible = false;
							this.auditForm = {
								id: '',
								partnerTradeNO: '',
								reason: '',
								status: 1 // 1通过，2拒绝
							};
							this.getData();
						}
					});
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
	.service-cell {
		display: flex;
		align-items: center;

		div {
			text-align: left;
			margin-left: 10px;
		}

		.doctor-avatar {
			width: 100px;
			height: 100px;
		}
	}

	.click-to-show {
		cursor: pointer;
		color: deepskyblue;
	}
</style>